import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import app from './app';
import apps from './apps';
import auth from './auth';
import account from './account';
import projects from './projects';
import marketing from './marketing';
import subtrack from './subtrack';
import evtrack from './evtrack';
import messaging from './messaging';

export default function configureStore() {
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

  const rootReducer = combineReducers({
    app: persistReducer(
      {
        key: 'app',
        storage,
        blacklist: ['loaded', 'isSidebarOpened'],
      },
      app,
    ),
    auth: persistReducer(
      {
        key: 'auth',
        storage,
        blacklist: ['error', 'pending'],
      },
      auth,
    ),
    account: persistReducer(
      {
        key: 'account',
        storage,
      },
      account,
    ),
    projects: persistReducer(
      {
        key: 'projects',
        storage,
        whitelist: ['selectedProjectId'],
      },
      projects,
    ),
    marketing: persistReducer(
      {
        key: 'marketing',
        storage,
        whitelist: [''],
      },
      marketing,
    ),
    subtrack: persistReducer(
      {
        key: 'subtrack',
        storage,
        whitelist: [''],
      },
      subtrack,
    ),
    evtrack: persistReducer(
      {
        key: 'evtrack',
        storage,
        whitelist: [''],
      },
      evtrack,
    ),
    messaging: persistReducer(
      {
        key: 'messaging',
        storage,
        whitelist: ['notificationTemplates', 'notificationTokens', 'notificationFavoriteTokens'],
      },
      messaging,
    ),
    apps,
  });

  const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(thunk)));

  const persistor = persistStore(store);

  return {
    store,
    persistor,
  };
}
