export const prepareStringToArray = (input) => {
  let trimmed = input.trim();
  trimmed = trimmed.replace(/'/g, '"');
  if (trimmed.startsWith('[') && trimmed.endsWith(']')) {
    try {
      const result = JSON.parse(trimmed);
      return result;
    } catch (e) {
      return [input];
    }
  } else {
    return [input];
  }
};
