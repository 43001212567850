import { createAction } from 'redux-actions';
import { v4 as uuid } from 'uuid';
import * as TYPES from './types';
import * as messagingApi from '../../api/messaging';

export const setPending = createAction(TYPES.SET_PENDING);
export const setNotificationsData = createAction(TYPES.SET_NOTIFICATIONS_DATA);
export const setNotificationsPending = createAction(TYPES.SET_NOTIFICATIONS_PENDING);
export const setNotificationTokens = createAction(TYPES.SET_NOTIFICATIONS_TOKENS);
export const setNotificationFavoriteTokens = createAction(TYPES.SET_NOTIFICATIONS_FAVORITE_TOKENS);
export const setNotificationsTemplates = createAction(TYPES.SET_NOTIFICATIONS_TEMPLATES);

export const getNotifications = () => async (dispatch) => {
  dispatch(setNotificationsPending(true));
  try {
    const notifications = await messagingApi.getNotifications();
    dispatch(setNotificationsData(notifications));
    return notifications;
  } catch (error) {
    console.log('[GET NOTIFICATIONS ERROR]', error);
    throw error;
  } finally {
    dispatch(setNotificationsPending(false));
  }
};

export const sendNotificationMessage = (data) => async (dispatch) => {
  dispatch(setPending(true));
  try {
    const response = await messagingApi.sendNotification(data);
    console.log('SENT RES: ', response);
    return response;
  } catch (error) {
    console.log('[SEND MESSAGE ERROR]', error);
    throw error;
  } finally {
    dispatch(setPending(false));
  }
};

export const addNotificationToken = (token) => (dispatch, getState) => {
  const {
    messaging: { notificationTokens },
  } = getState();

  dispatch(setNotificationTokens([...notificationTokens, token]));
};

export const addNotificationTokensToFavorite = (toks) => (dispatch, getState) => {
  const {
    messaging: { notificationFavoriteTokens, notificationTokens },
  } = getState();

  const filteredTokens = notificationTokens.filter((t) => !toks.includes(t));
  const filteredFavoriteTokens = notificationFavoriteTokens.filter((t) => !toks.includes(t));
  dispatch(setNotificationFavoriteTokens([...filteredFavoriteTokens, ...notificationTokens]));
  dispatch(setNotificationTokens(filteredTokens));
};

export const removeNotificationFavoriteToken = (token) => (dispatch, getState) => {
  const {
    messaging: { notificationFavoriteTokens },
  } = getState();

  const filteredTokens = notificationFavoriteTokens.filter((t) => t !== token);

  dispatch(setNotificationFavoriteTokens(filteredTokens));
};

export const removeNotificationToken = (token) => (dispatch, getState) => {
  const {
    messaging: { notificationTokens },
  } = getState();

  const filteredTokens = notificationTokens.filter((t) => t !== token);

  dispatch(setNotificationTokens(filteredTokens));
};

export const addNotificationTemplate = (template) => (dispatch, getState) => {
  const {
    messaging: { notificationTemplates },
  } = getState();

  dispatch(
    setNotificationsTemplates([
      ...notificationTemplates,
      {
        ...template,
        id: uuid(),
      },
    ]),
  );
};

export const removeNotificationTemplate = (id) => (dispatch, getState) => {
  const {
    messaging: { notificationTemplates },
  } = getState();

  const filteredTemplates = notificationTemplates.filter((t) => t.id !== id);
  dispatch(setNotificationsTemplates(filteredTemplates));
};
