import React, { useState, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Delete as RemoveIcon } from '@material-ui/icons';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Typography from '@material-ui/core/Typography';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import { addNotificationTemplate, setNotificationsTemplates, removeNotificationTemplate } from '../../../store/messaging/actions';
// components
import Widget from '../../../components/Widget/Widget';
import { ROUTES, NAMES, IDS } from '../../../constants/routes';
import Breadcrumbs from '../../../components/Breadcrumbs';

const breadcrumsRoutes = [
  {
    route: ROUTES[IDS.MESSAGING],
    name: NAMES[IDS.MESSAGING],
  },
];

const NotificationTemplates = () => {
  // const navigate = useNavigate();
  const classes = useStyles();
  const [group, setGroup] = useState('');
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [newGroupValue, setNewGroupValue] = useState('');
  const [createdGroups, setCreatedGroups] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [isGroupModalOpen, setGroupModalOpen] = useState(false);
  const [isGroupModalCreatingMode, setGroupModalCreatingMode] = useState(false);

  const dispatch = useDispatch();
  const isPending = useSelector((state) => state.messaging.pending);
  const templates = useSelector((state) => state.messaging.notificationTemplates);

  const groups = useMemo(() => {
    const created = createdGroups.reduce(
      (acc, g) => ({
        ...acc,
        [g]: [],
      }),
      {},
    );

    if (!templates.length) {
      return created;
    }

    return templates.reduce((acc, t) => {
      const grp = t.group ? t.group : 'Unknown';
      return {
        ...created,
        ...acc,
        [grp]: acc?.[grp] ? [...acc[grp], t] : [t],
      };
    }, {});
  }, [templates, createdGroups]);

  const handleNameChange = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const handleTitleChange = (e) => {
    e.preventDefault();
    setTitle(e.target.value);
  };

  const handleBodyChange = (e) => {
    e.preventDefault();
    setBody(e.target.value);
  };

  const handleModalOpen = () => {
    setGroupModalOpen(true);
  };

  const handleCreateGroup = (e) => {
    e.preventDefault();
    setGroupModalCreatingMode(true);
    handleModalOpen();
  };

  const handleAddToGroup = (e) => {
    e.preventDefault();
    setGroupModalCreatingMode(false);
    handleModalOpen();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name.length && title.length && body.length) {
      dispatch(
        addNotificationTemplate({
          group,
          name: name.trim(),
          title: title.trim(),
          body: body.trim(),
        }),
      );

      setName('');
      setTitle('');
      setBody('');
      setGroup('');
    }
  };

  const onGroupModalClose = () => {
    setGroupModalOpen(false);
  };

  const handleCreatingGroupChange = (e) => {
    e.preventDefault();
    setNewGroupValue(e.target.value);
  };

  const handleGroupSubmit = (e) => {
    e.preventDefault();
    if (isGroupModalCreatingMode) {
      setCreatedGroups([...createdGroups.filter((i) => i !== newGroupValue), newGroupValue]);
    } else {
      const updTemps = templates.map((template) => {
        if (selectedTemplates.includes(template.id)) {
          return {
            ...template,
            group,
          };
        }
        return template;
      });
      dispatch(setNotificationsTemplates(updTemps));
    }

    setGroup('');
    setNewGroupValue('');
    setGroupModalCreatingMode(false);
    setGroupModalOpen(false);
  };

  const renderGroupSelect = () => {
    if (!groups) {
      return null;
    }

    const handleGroupChange = (e) => {
      e.preventDefault();
      const { value } = e.target;
      setGroup(value);
    };

    return (
      <TextField
        id="groups"
        name="groups"
        select
        label="Groups"
        value={group}
        className={classNames(classes.field, classes.fieldSelector)}
        onChange={handleGroupChange}
        variant="outlined"
      >
        {Object.keys(groups).map((g) => (
          <MenuItem key={g} value={g}>
            {g}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  const renderTemplate = (t) => {
    const labelId = `checkbox-list-label-${t.id}`;

    const handleRemove = (e) => {
      e.preventDefault();
      dispatch(removeNotificationTemplate(t.id));
    };

    const handleCheckToggle = (e) => {
      e.preventDefault();
      const currentIndex = selectedTemplates.findIndex((id) => id === t.id);
      const newSelected = [...selectedTemplates];

      if (currentIndex === -1) {
        newSelected.push(t.id);
      } else {
        newSelected.splice(currentIndex, 1);
      }

      setSelectedTemplates(newSelected);
    };

    return (
      <ListItem key={t.id} divider disablePadding dense className={classes.item}>
        <ListItemButton role={undefined} onClick={handleCheckToggle} dense>
          <ListItemIcon className={classes.itemIcon}>
            <Checkbox
              edge="start"
              checked={selectedTemplates.includes(t.id)}
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': labelId }}
            />
          </ListItemIcon>
          <div className={classes.itemTextWrap}>
            <Typography noWrap color="textPrimary" variant="subtitle1" className={classes.itemTitle}>
              {t.name}
            </Typography>
            <Typography noWrap color="textSecondary" variant="subtitle2" className={classes.itemTitle}>
              {t.title}
            </Typography>
            <Typography noWrap color="textSecondary" variant="caption" className={classes.itemText}>
              {t.body}
            </Typography>
          </div>
        </ListItemButton>

        <ListItemSecondaryAction className={classes.rightButton}>
          <IconButton
            // color="secondary"
            edge="start"
            aria-label="remove"
            onClick={handleRemove}
            className={classes.removeButton}
          >
            <RemoveIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  const renderTemplates = () => {
    if (!groups) {
      return null;
    }
    return Object.keys(groups).map((g) => (
      <Accordion className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography className={classes.heading}>{g}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={12}>
            <List className={classes.list}>{groups[g].map(renderTemplate)}</List>
          </Grid>
        </AccordionDetails>
      </Accordion>
    ));
  };

  return (
    <>
      <Grid container xs={12}>
        <Grid item xs={10}>
          <Breadcrumbs links={breadcrumsRoutes} currentRoute={NAMES[IDS.NOTIFICATION_TEMPLATES]} />
        </Grid>
      </Grid>
      <Grid container spacing={4} className={classes.main}>
        <Grid item xs={12}>
          <Widget disableWidgetMenu bodyClass={classes.tableTopOverflow}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <Grid container xs={12} spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        className={classNames(classes.field, classes.fieldTitle)}
                        name="name"
                        id="name"
                        label="name"
                        value={name}
                        error={false}
                        onChange={handleNameChange}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classNames(classes.field, classes.fieldTitle)}
                        name="title"
                        id="title"
                        label="title"
                        value={title}
                        error={false}
                        onChange={handleTitleChange}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classNames(classes.field, classes.fieldBody)}
                        name="body"
                        id="body"
                        label="body"
                        value={body}
                        error={false}
                        onChange={handleBodyChange}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      {renderGroupSelect()}
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        type="submit"
                        className={classes.button}
                        onClick={handleSubmit}
                      >
                        Create
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
              <Grid item xs={6}>
                <Grid container xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      type="button"
                      className={classes.button}
                      onClick={handleCreateGroup}
                    >
                      Create Group
                    </Button>
                  </Grid>

                  <Grid item xs={12}>
                    {renderTemplates()}
                  </Grid>

                  {selectedTemplates.length > 0 && (
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        type="button"
                        className={classes.button}
                        onClick={handleAddToGroup}
                      >
                        Add to Group
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {isPending && (
                <div className={classes.loaderWrap}>
                  <div className={classes.loader}>
                    <CircularProgress size={26} />
                  </div>
                </div>
              )}
            </Grid>
          </Widget>
        </Grid>
      </Grid>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isGroupModalOpen}
        onClose={onGroupModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isGroupModalOpen}>
          <Grid container xs={9} className={classes.modalContainer}>
            <Grid item xs={12}>
              {isPending ? (
                <CircularProgress size={26} className={classes.loader} />
              ) : (
                <Grid container xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <div className={classes.modalHeader}>
                      <h2 className={classes.modalTitle}>{isGroupModalCreatingMode ? 'Create Group' : 'Select Group'}</h2>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <form className={classes.modalForm} noValidate autoComplete="off" onSubmit={handleGroupSubmit}>
                      <Grid container xs={12} spacing={2}>
                        <Grid item xs={12}>
                          {isGroupModalCreatingMode ? (
                            <TextField
                              className={classes.field}
                              name="group"
                              id="group"
                              label={isGroupModalCreatingMode ? 'New Group Name' : 'Groups'}
                              value={newGroupValue}
                              error={false}
                              onChange={handleCreatingGroupChange}
                              variant="outlined"
                            />
                          ) : (
                            renderGroupSelect()
                          )}
                        </Grid>

                        <Grid item xs={12}>
                          <Button
                            className={classes.button}
                            variant="contained"
                            size="large"
                            color="primary"
                            type="submit"
                            onClick={handleGroupSubmit}
                          >
                            Submit
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Fade>
      </Modal>
    </>
  );
};

export default NotificationTemplates;

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    position: 'relative',
  },
  root: {
    width: '100%',
  },
  main: {
    marginTop: theme.spacing(2),
  },
  tableTopOverflow: {
    display: 'flex',
    flexDirection: 'row',
  },
  filtersLoaderWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    marginTop: theme.spacing(2),
    minHeight: theme.spacing(4),
  },
  filters: {},
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  field: {
    width: '100%',
  },
  fieldTitle: {},
  filedWrapToken: {
    position: 'relative',
  },
  button: {
    width: '100%',
  },
  tokens: {
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(2),
  },
  list: {
    overflow: 'hidden',
  },
  item: {
    padding: 0,
  },
  itemIcon: {
    minWidth: '0 !important',
  },
  itemTextWrap: {
    maxWidth: '100%',
  },
  itemTitle: {},
  itemText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  rightButton: {
    right: '0 !important',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,1) !important',
  },
  removeButton: {},
  loaderWrap: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.7)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9,
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minHeight: 200,
    overflowX: 'hidden',
    overflowY: 'scroll',
    position: 'relative',
  },
  modalForm: {
    width: '100%',
  },
  modalHeader: {
    marginTop: theme.spacing(2),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  modalTitle: {
    fontSize: 20,
    margin: 0,
  },
}));
