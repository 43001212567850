import React, { useState, useCallback, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import { Add as AddIcon, Delete as RemoveIcon } from '@material-ui/icons';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Typography from '@material-ui/core/Typography';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  sendNotificationMessage,
  addNotificationToken,
  addNotificationTokensToFavorite,
  removeNotificationToken,
  setNotificationTokens,
  removeNotificationFavoriteToken,
} from '../../../store/messaging/actions';

import Widget from '../../../components/Widget/Widget';
import { ROUTES, NAMES, IDS } from '../../../constants/routes';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { prepareStringToArray } from '../../../utils/array';

const breadcrumsRoutes = [
  {
    route: ROUTES[IDS.MESSAGING],
    name: NAMES[IDS.MESSAGING],
  },
];

const Notification = () => {
  // const navigate = useNavigate();
  const classes = useStyles();
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [token, setTokenValue] = useState('');
  const [checkedTokens, setCheckedTokens] = useState([]);
  const [checkedFavoriteTokens, setCheckedFavoriteTokens] = useState([]);
  const dispatch = useDispatch();
  const isPending = useSelector((state) => state.messaging.pending);
  const favoriteTokens = useSelector((state) => state.messaging.notificationFavoriteTokens);
  const tokens = useSelector((state) => state.messaging.notificationTokens);
  const templates = useSelector((state) => state.messaging.notificationTemplates);
  const allCheckedTokes = [...checkedTokens, ...checkedFavoriteTokens];

  const groups = useMemo(
    () =>
      templates.reduce((acc, t) => {
        const grp = t.group ? t.group : 'Unknown';
        return {
          ...acc,
          [grp]: acc?.[grp] ? [...acc[grp], t] : [t],
        };
      }, {}),
    [templates],
  );

  const handleTitleChange = (e) => {
    e.preventDefault();
    setTitle(e.target.value);
  };

  const handleBodyChange = (e) => {
    e.preventDefault();
    setBody(e.target.value);
  };

  const handleTokenChange = (e) => {
    e.preventDefault();
    setTokenValue(e.target.value);
  };

  const handleTemplateClick = useCallback(
    (template) => {
      setTitle(template.title);
      setBody(template.body);
    },
    [templates],
  );

  const handleTokenAdd = (e) => {
    e.preventDefault();

    const t = prepareStringToArray(token);
    const filteredTokens = t.filter((i) => !tokens.includes(i));
    filteredTokens.map((i) => {
      dispatch(addNotificationToken(i));
      return i;
    });

    setCheckedTokens(filteredTokens);
    setTokenValue('');
  };

  const handleTokenAddToFavorites = (e) => {
    e.preventDefault();
    dispatch(addNotificationTokensToFavorite(checkedTokens));
    setCheckedTokens([]);
  };

  const handleRemoveTokens = (e) => {
    e.preventDefault();

    if (checkedTokens.length) {
      const filteredTokens = tokens.filter((i) => !checkedTokens.includes(i));
      dispatch(setNotificationTokens(filteredTokens));
    } else {
      dispatch(setNotificationTokens([]));
    }

    setCheckedTokens([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (title.length && body.length && allCheckedTokes.length) {
      try {
        await dispatch(
          sendNotificationMessage({
            to: allCheckedTokes,
            message: {
              title,
              body,
            },
          }),
        );
        setTitle('');
        setBody('');
        alert('Notification sent successfully');
      } catch (error) {
        alert('Notification didn`t send');
      }
    }
  };

  const renderTemplate = (t) => {
    const handleCheckToggle = (e) => {
      e.preventDefault();
      handleTemplateClick(t);
    };

    return (
      <ListItem key={t.id} divider disablePadding dense className={classes.item}>
        <ListItemButton role={undefined} onClick={handleCheckToggle} dense>
          <div className={classes.itemTextWrap}>
            <Typography noWrap color="textPrimary" variant="subtitle1" className={classes.itemTitle}>
              {t.name}
            </Typography>
            <Typography noWrap color="textSecondary" variant="subtitle2" className={classes.itemTitle}>
              {t.title}
            </Typography>
            <Typography noWrap color="textSecondary" variant="caption" className={classes.itemText}>
              {t.body}
            </Typography>
          </div>
        </ListItemButton>
      </ListItem>
    );
  };

  const renderTemplates = () => {
    if (!groups) {
      return null;
    }
    return Object.keys(groups).map((g) => (
      <Accordion className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography className={classes.heading}>{g}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={12}>
            <List className={classes.list}>{groups[g].map(renderTemplate)}</List>
          </Grid>
        </AccordionDetails>
      </Accordion>
    ));
  };

  const renderTokenItem = (t, index, isFavorite) => {
    const labelId = `checkbox-list-label-${t}`;
    const checkedTks = isFavorite ? checkedFavoriteTokens : checkedTokens;

    const handleRemoveToken = (e) => {
      e.preventDefault();

      if (isFavorite) {
        dispatch(removeNotificationFavoriteToken(t));
      } else {
        dispatch(removeNotificationToken(t));
      }
    };

    const handleCheckToggle = (e) => {
      e.preventDefault();
      const currentIndex = checkedTks.indexOf(t);
      const newChecked = [...checkedTks];

      if (currentIndex === -1) {
        newChecked.push(t);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      if (isFavorite) {
        setCheckedFavoriteTokens(newChecked);
      } else {
        setCheckedTokens(newChecked);
      }
    };

    return (
      <ListItem key={`${t}-${index}`} disablePadding dense className={classes.item}>
        <ListItemButton role={undefined} onClick={handleCheckToggle} dense>
          <ListItemIcon className={classes.itemIcon}>
            <Checkbox
              edge="start"
              checked={checkedTks.indexOf(t) !== -1}
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': labelId }}
            />
          </ListItemIcon>
          <div className={classes.itemTextWrap}>
            <Typography noWrap className={classes.itemText}>
              {t}
            </Typography>
          </div>
        </ListItemButton>

        <ListItemSecondaryAction className={classes.rightButton}>
          <IconButton
            // color="secondary"
            edge="start"
            aria-label="remove"
            onClick={handleRemoveToken}
            className={classes.removeButton}
          >
            <RemoveIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  return (
    <>
      <Grid container xs={12}>
        <Grid item xs={10}>
          <Breadcrumbs links={breadcrumsRoutes} currentRoute={NAMES[IDS.NOTIFICATION]} />
        </Grid>
      </Grid>
      <Grid container spacing={4} className={classes.main}>
        <Grid item xs={12}>
          <Widget disableWidgetMenu bodyClass={classes.tableTopOverflow}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <TextField
                    className={classNames(classes.field, classes.fieldTitle)}
                    name="title"
                    id="title"
                    label="title"
                    value={title}
                    error={false}
                    onChange={handleTitleChange}
                    variant="outlined"
                  />
                  <TextField
                    className={classNames(classes.field, classes.fieldBody)}
                    name="body"
                    id="body"
                    label="body"
                    value={body}
                    error={false}
                    onChange={handleBodyChange}
                    variant="outlined"
                  />

                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    type="submit"
                    disabled={!allCheckedTokes.length || !title.trim().length || !body.trim().length}
                    className={classes.button}
                    onClick={handleSubmit}
                  >
                    Send to {allCheckedTokes.length} Users
                  </Button>
                </form>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.filedWrapToken}>
                  <TextField
                    className={classNames(classes.field, classes.fieldToken)}
                    name="token"
                    id="token"
                    label="FCM token"
                    value={token}
                    error={false}
                    onChange={handleTokenChange}
                    variant="outlined"
                  />
                  <Fab
                    color="primary"
                    aria-label="add"
                    size="small"
                    onClick={handleTokenAdd}
                    className={classes.addButton}
                    disabled={!token.length}
                  >
                    <AddIcon />
                  </Fab>
                </div>

                {favoriteTokens.length > 0 && (
                  <Accordion className={classes.accordion}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                      <Typography className={classes.heading}>Favorite</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item xs={12}>
                        <List className={classes.list}>{favoriteTokens.map((t, index) => renderTokenItem(t, index, true))}</List>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )}

                <List className={classes.list}>{tokens.map((t, index) => renderTokenItem(t, index))}</List>

                <Grid container xs={12} justifyContent="space-between" spacing={2}>
                  <Grid item xs={6}>
                    {checkedTokens.length > 0 && (
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        type="submit"
                        className={classes.button}
                        onClick={handleTokenAddToFavorites}
                      >
                        Add to Favorites
                      </Button>
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    {tokens.length > 0 && (
                      <Button
                        variant="contained"
                        size="large"
                        color="secondary"
                        type="submit"
                        className={classes.button}
                        onClick={handleRemoveTokens}
                      >
                        {checkedTokens.length > 0 ? `Remove Selected (${checkedTokens.length})` : 'Remove All'}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {isPending && (
                <div className={classes.loaderWrap}>
                  <div className={classes.loader}>
                    <CircularProgress size={26} />
                  </div>
                </div>
              )}
            </Grid>
          </Widget>
        </Grid>
      </Grid>

      <Grid container spacing={4} className={classes.main}>
        <Grid item xs={12}>
          <Widget title="Templates" disableWidgetMenu bodyClass={classes.tableTopOverflow}>
            <Grid item xs={12}>
              {renderTemplates()}
            </Grid>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
};

export default Notification;

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    position: 'relative',
  },
  root: {
    width: '100%',
  },
  main: {
    marginTop: theme.spacing(2),
  },
  tableTopOverflow: {
    display: 'flex',
    flexDirection: 'row',
  },
  filtersLoaderWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    marginTop: theme.spacing(2),
    minHeight: theme.spacing(4),
  },
  filters: {},
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  field: {
    width: '100%',
  },
  fieldBody: {
    marginTop: theme.spacing(2),
  },
  filedWrapToken: {
    position: 'relative',
  },
  button: {
    width: '100%',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  addButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    transform: 'translate(0, -50%)',
  },
  tokens: {
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(2),
  },
  list: {
    width: '100%',
    overflow: 'hidden',
  },
  item: {
    width: '100%',
    padding: 0,
  },
  itemIcon: {
    minWidth: '0 !important',
  },
  itemTempalateTextWrap: {
    display: 'flex',
  },
  itemTextWrap: {
    maxWidth: '100%',
  },
  itemText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  rightButton: {
    right: '0 !important',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,1) !important',
  },
  removeButton: {},
  loaderWrap: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.7)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9,
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));
